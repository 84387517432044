import { MoleculesTaxonomyMasterCategoryListQuery } from '../../../../types/graphql-types'

import { blog_post_card_type } from '../../atoms/BlogPostCard'
import { blog_post_core } from './blog_post_core'

// 新着記事を除外する処理
type reject_new_articles_type = {
  (
    blog_post_list: blog_post_card_type[],
    reject_id_list: string[],
    minimum_articles_displayed: number
  ): blog_post_card_type[]
}

const reject_new_articles: reject_new_articles_type = (
  blog_post_list,
  reject_id_list,
  minimum_articles_displayed = 3
) => {
  // 最低3つは記事があることを保証
  if (blog_post_list.length <= minimum_articles_displayed) {
    return blog_post_list
  }
  // spliceで配列に対して破壊的処理をするのでDeepCopy
  const result = JSON.parse(JSON.stringify(blog_post_list))
  // 記事数が最低保証と除外数以上あれば全て除外、それ以下なら最低保証超過分を除外
  const execution: number =
    blog_post_list.length >= minimum_articles_displayed + reject_id_list.length
      ? reject_id_list.length
      : blog_post_list.length - minimum_articles_displayed

  for (let i = 0; i < execution; i += 1) {
    for (const [index, blog_post] of result.entries()) {
      if (reject_id_list.includes(blog_post.id)) {
        result.splice(index, 1)
        break
      }
    }
  }
  return result
}

// 新着4番目の記事にclasNameを追加
type add_custom_class_name_type = {
  (
    blog_post_list: blog_post_card_type[],
    exclude_id_other_than_tablet: string
  ): blog_post_card_type[]
}

const add_custom_class_name: add_custom_class_name_type = (
  blog_post_list,
  exclude_id_other_than_tablet
) => {
  return blog_post_list.map((elem) => {
    if (elem.id === exclude_id_other_than_tablet) {
      const result = elem
      result.custom_class_name = 'hide_in_tablet_size'
      return result
    }
    return elem
  })
}

type category_type =
  MoleculesTaxonomyMasterCategoryListQuery['allContentfulMasterCategory']['edges'][0]['node']['category']

type extract_master_category_articles_type = (
  category_list: category_type,
  pickup_number?: number,
  top_page_list?: string[] | null
) => JSX.Element[]

export const extract_master_category_articles: extract_master_category_articles_type =
  (category_list, pickup_number, top_page_id_list) => {
    const number_of_new_articles = 4 // 新着記事の数
    const minimum_articles_displayed = 3 // 最低記事表示数

    const union_blog_post_list = category_list.flatMap((elem) => {
      return elem.blog_post ? elem.blog_post : []
    })

    // 新着記事を除外
    let adjust_blog_post_list: blog_post_card_type[]
    if (top_page_id_list) {
      // タブレット用の新着4番目の記事のidを除外して保持(popするのでreject_new_articlesより前に実行)
      let exclude_id_other_than_tablet = ''
      if (top_page_id_list.length >= number_of_new_articles) {
        exclude_id_other_than_tablet = top_page_id_list.pop()
      }
      // 新着1-3番目に該当する記事を排除
      adjust_blog_post_list = reject_new_articles(
        union_blog_post_list,
        top_page_id_list,
        minimum_articles_displayed
      )
      // タブレットサイズのみ新着に移動する新着4番目の記事にクラス名を付与（新着が4つあり、最低保証より記事数が多い場合）
      if (
        exclude_id_other_than_tablet &&
        union_blog_post_list.length >= minimum_articles_displayed
      ) {
        adjust_blog_post_list = add_custom_class_name(
          adjust_blog_post_list,
          exclude_id_other_than_tablet
        )
      }
    } else {
      adjust_blog_post_list = union_blog_post_list
    }

    const result = blog_post_core(adjust_blog_post_list, pickup_number)

    return result
  }
