import { css } from '@emotion/react'

import { common_color, media_mobile } from '../../../style/common_value'

// カード全体のスタイル

const blog_card_sp_size = {
  minHeight: '140px',
  borderRadius: '4px',
  border: `solid 1px ${common_color.gray}`,
}
const blog_card_pc_size = {
  minHeight: '360px',
  borderRadius: '0',
  border: 'none',
}

const background_color = {
  backgroundColor: common_color.white,
}

// 表示画像部分

const blog_card_image_sp_size = {
  ...background_color,
  zIndex: 1,
  width: '45%',
  height: '100%',
  top: '0',
  left: '0',
}

const blog_card_image_pc_size = {
  width: '80%',
  height: '60%',
  top: '2px',
  left: '2px',
  boxShadow: `2px 2px 4px 2px ${common_color.gray}`,
  borderRadius: '4px',
  borderStyle: 'none',
  borderWidth: '0',
}

// タイトル表示部分

const blog_card_info_sp_size = {
  ...background_color,
  width: '55%',
  height: '100%',
  bottom: '0',
  right: '0',
}

const blog_card_info_pc_size = {
  width: '75%',
  height: '50%',
  bottom: '4px',
  right: '4px',
  boxShadow: `2px 2px 4px 2px ${common_color.gray_light}`,
  borderRadius: '4px',
  borderStyle: 'none',
  borderWidth: '0',
}

export const card_style = css({
  ...blog_card_sp_size,
  position: 'relative',
  '&  picture > img': {
    transition:
      'width .25s, height .25s, top .25s, left .25s, opacity .25s linear!important',
  },
  '&:hover picture > img': {
    width: 'calc(100% + 40px)',
    height: 'calc(100% + 40px)',
    top: '-20px',
    left: '-20px',
    transition: 'width .25s, height .25s, top .25s, left .25s',
  },
  '.gatsby-image-wrapper': {
    ...blog_card_image_sp_size,
    [media_mobile.tab]: {
      ...blog_card_image_pc_size,
    },
  },
  [media_mobile.tab]: {
    ...blog_card_pc_size,
  },
})

export const post_card_category_style = css({
  position: 'absolute',
  fontSize: '1.1em',
  zIndex: 2,
  top: '4px',
  left: '2px',
  textShadow: common_color.gray_gradation,
  color: 'white',
  padding: '.2rem .4rem',
  borderRadius: '4px 0 4px 0',
  [media_mobile.tab]: {
    top: '6px',
    left: '2px',
  },
})

export const blog_card_info_container_style = css({
  ...blog_card_info_sp_size,
  position: 'absolute',
  [media_mobile.tab]: {
    ...blog_card_info_pc_size,
  },
})

export const post_card_info_style = css({
  height: '100%',
  textAlign: 'center',
  padding: '0 .1rem .2rem',
  [media_mobile.tab]: {
    padding: '17% .2rem .2rem',
  },
})

export const post_card_title_style = css({
  overflow: 'hidden',
  fontSize: '1em',
  lineHeight: '1.2',
  height: '90%',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  [media_mobile.tab]: {
    fontSize: '1.3em',
    lineHeight: '1.3',
  },
})

export const post_card_publish_date_style = css({
  zIndex: 1,
  backgroundColor: 'white',
  fontSize: '.8em',
  height: '10%',
})
