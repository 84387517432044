import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { TaxonomyMetadataQuery } from '../../../types/graphql-types'

import {
  PostMetadata,
  JsonLdMetadata,
  ogp_image_type,
  metadata_type,
  bread_crumb_list_type,
} from './MetadataCore'

export type input_meta_data_type = {
  page_title?: string
  ogp_images?: ogp_image_type[]
  master_category_name?: string
  master_category_slug?: string
  category_name?: string
  category_slug?: string
  tag_name?: string
  tag_slug?: string
  published_date: string
  modified_date: string
}

type props_type = {
  content_type: 'All' | 'MasterCategory' | 'Category' | 'Tag'
  input_meta_data?: input_meta_data_type
}

type metadata_addition_type = {
  page_title?: string
  page_fullpath?: string
  ogp_images?: ogp_image_type[]
  bread_crumb_list?: bread_crumb_list_type
}

export const TaxonomyMetadata: React.FC<props_type> = ({
  content_type,
  input_meta_data,
}) => {
  const query: TaxonomyMetadataQuery = useStaticQuery(graphql`
    query TaxonomyMetadata {
      contentfulSiteMetaData {
        site_title
        site_url
        site_description
        author_data {
          author_name
          url
        }
        ogp_images {
          title
          description
          file {
            fileName
            url
            details {
              image {
                width
                height
              }
            }
          }
        }
        logo_image {
          file {
            url
          }
        }
      }
    }
  `)
  const site_metadata = query.contentfulSiteMetaData
  const metadata_base: metadata_type = {
    page_type: 'article' as const,
    site_title: site_metadata.site_title,
    page_title: site_metadata.site_title,
    page_description: site_metadata.site_description,
    ogp_images: site_metadata.ogp_images,
    logo_image: site_metadata.logo_image.file.url,
    author: site_metadata.author_data,
    page_fullpath: `${site_metadata.site_url}`,
    published_date: input_meta_data.published_date,
    modified_date: input_meta_data.modified_date,
  }

  let metadata_addition: metadata_addition_type = {}

  switch (content_type) {
    case 'All':
      metadata_addition = {
        page_title: `全ての記事 | ${site_metadata.site_title}`,
        page_fullpath: `${site_metadata.site_url}/all_post`,
      }
      break
    case 'MasterCategory':
      metadata_addition = {
        page_title: `${input_meta_data.master_category_name} | ${site_metadata.site_title}`,
        ogp_images: input_meta_data.ogp_images ?? site_metadata.ogp_images,
        page_fullpath: `${site_metadata.site_url}/${input_meta_data.master_category_slug}`,
      }
      break
    case 'Category':
      metadata_addition = {
        page_title: `${input_meta_data.category_name} | ${site_metadata.site_title}`,
        ogp_images: input_meta_data.ogp_images ?? site_metadata.ogp_images,
        page_fullpath: `${site_metadata.site_url}/${input_meta_data.master_category_slug}/${input_meta_data.category_slug}`,
      }
      break
    case 'Tag':
      metadata_addition = {
        page_title: `${input_meta_data.tag_name} | ${site_metadata.site_title}`,
        ogp_images: input_meta_data.ogp_images ?? site_metadata.ogp_images,
        page_fullpath: `${site_metadata.site_url}/tag/${input_meta_data.tag_slug}`,
      }
      break
    default:
      break
  }

  // taxonomy系ページにnoindexをつけて全てGoogleに登録させない設定
  const no_index = { no_index: true }

  const metadata = { ...metadata_base, ...metadata_addition, ...no_index }

  return (
    <>
      <PostMetadata metadata={metadata} />
      <JsonLdMetadata metadata={metadata} />
    </>
  )
}

export default TaxonomyMetadata
