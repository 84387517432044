import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import { MoleculesTaxonomyAllPostListQuery } from '../../../../types/graphql-types'

import { blog_post_core } from './blog_post_core'
import TaxonomyMetadata from '../../metadata/TaxonomyMetadata'

type props_type = {
  pickup_number?: number
  enable_metadata?: boolean
}

export const TaxonomyAllPostList: React.FC<props_type> = ({
  pickup_number = 0,
  enable_metadata = false,
}) => {
  const query_data: MoleculesTaxonomyAllPostListQuery = useStaticQuery(graphql`
    query MoleculesTaxonomyAllPostList {
      allContentfulBlogPost(
        limit: 1000
        sort: { fields: published_date, order: DESC }
      ) {
        edges {
          node {
            title
            id
            slug
            published_date
            modified_date
            category {
              name
              slug
              master_category {
                name
                slug
              }
              thumbnail {
                title
                gatsbyImageData(placeholder: BLURRED)
              }
            }
            thumbnail {
              title
              gatsbyImageData(placeholder: BLURRED)
            }
          }
        }
      }
    }
  `)
  const blog_post_list = []
  for (const i of query_data.allContentfulBlogPost.edges) {
    blog_post_list.push(i.node)
  }
  const result: (JSX.Element | JSX.Element[])[] = []
  result.push(blog_post_core(blog_post_list, pickup_number))

  const meta_data = {
    published_date:
      query_data.allContentfulBlogPost.edges[0].node.published_date,
    modified_date: query_data.allContentfulBlogPost.edges[0].node.modified_date,
  }

  return (
    <>
      {enable_metadata && (
        <TaxonomyMetadata content_type="All" input_meta_data={meta_data} />
      )}
      <div className="taxonomy_title" key="all_articles">
        全ての記事
      </div>
      {result}
    </>
  )
}

export default TaxonomyAllPostList
