import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { PostMetadataQuery } from '../../../../types/graphql-types'

import { metadata_props_type } from './MetadataTypes'

export const PostMetadata: React.FC<metadata_props_type> = ({ metadata }) => {
  const {
    page_type,
    site_title,
    page_title,
    page_fullpath,
    page_description,
    ogp_images,
    tag_names,
    published_date,
    modified_date,
    no_index,
    category_name,
  } = metadata

  const { contentfulSiteMetaData: site_metadata }: PostMetadataQuery =
    useStaticQuery(
      graphql`
        query PostMetadata {
          contentfulSiteMetaData {
            site_url
            ogp_download_folder_name
          }
        }
      `
    )

  const image_path = `${site_metadata.site_url}/${site_metadata.ogp_download_folder_name}/`
  const add_category_title = category_name
    ? `【${category_name}】${page_title}`
    : `${page_title}`

  const robots_index = no_index ? 'noindex,nofollow' : 'index,follow'

  const og_locale = 'ja_JP'
  const og_type = page_type ?? 'article'

  const twitter_card_style = 'summary'
  const twitter_id = '@abarabakuhatsu'

  return (
    <Helmet>
      <link rel="canonical" href={page_fullpath} />

      <title>{add_category_title}</title>
      <meta name="description" content={page_description} />
      <meta name="robots" content={robots_index} />
      <meta name="googlebot" content={robots_index} />

      <meta name="og:url" content={page_fullpath} />
      <meta name="og:locale" content={og_locale} />
      <meta name="og:site_name" content={site_title} />

      <meta name="og:type" content={og_type} />
      <meta name="og:title" content={add_category_title} />
      <meta name="og:description" content={page_description} />

      {/* 最初のOPGのみ対応 */}
      <meta
        name="og:image"
        content={image_path + ogp_images[0].file.fileName}
      />
      <meta
        name="og:image:alt"
        content={ogp_images[0].description || ogp_images[0].title}
      />
      <meta
        name="og:image:width"
        content={String(ogp_images[0].file.details.image.width)}
      />
      <meta
        name="og:image:height"
        content={String(ogp_images[0].file.details.image.height)}
      />

      <meta name="twitter:card" content={twitter_card_style} />
      <meta name="twitter:site" content={twitter_id} />
      <meta name="twitter:creator" content={twitter_id} />

      <meta name="article:published_time" content={published_date} />
      <meta
        name="article:modified_time"
        content={modified_date ?? published_date}
      />
      {tag_names &&
        tag_names.map((tag): JSX.Element => {
          return <meta name="article:tag" content={tag} key={tag} />
        })}
    </Helmet>
  )
}
