import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'

import { MoleculesTaxonomyMasterCategoryListQuery } from '../../../../types/graphql-types'
import { master_category_build_checker } from '../../../utils'

import { extract_master_category_articles } from './extract_master_category_articles'
import {
  TaxonomyMetadata,
  input_meta_data_type,
} from '../../metadata/TaxonomyMetadata'

type props_type = {
  target_slug: string
  pickup_number?: number
  enable_metadata?: boolean
  top_page?: boolean
}

export const TaxonomyMasterCategoryList: React.FC<props_type> = ({
  target_slug,
  pickup_number,
  enable_metadata = false,
  top_page = false,
}) => {
  const query_data: MoleculesTaxonomyMasterCategoryListQuery =
    useStaticQuery(graphql`
      query MoleculesTaxonomyMasterCategoryList {
        allContentfulMasterCategory(
          limit: 1000
          sort: { fields: category___blog_post___published_date, order: DESC }
        ) {
          edges {
            node {
              name
              id
              slug
              category {
                id
                slug
                blog_post {
                  title
                  id
                  slug
                  published_date
                  modified_date
                  category {
                    name
                    slug
                    master_category {
                      name
                      slug
                    }
                    thumbnail {
                      title
                      gatsbyImageData(placeholder: BLURRED)
                    }
                  }
                  thumbnail {
                    title
                    gatsbyImageData(placeholder: BLURRED)
                  }
                }
              }
            }
          }
        }
        allContentfulBlogPost(
          sort: { fields: published_date, order: DESC }
          limit: 4
        ) {
          edges {
            node {
              id
              slug
            }
          }
        }
      }
    `)
  const result: (JSX.Element | JSX.Element[])[] = []
  for (const i of query_data.allContentfulMasterCategory.edges) {
    if (i.node.slug !== target_slug) {
      // eslint-disable-next-line no-continue
      continue
    }
    // カテゴリ、及び記事が一つも存在しなければ中断（push前のbreakで空の配列がreturnされる）
    if (!master_category_build_checker(i)) {
      break
    }

    // 新着記事4つのIDリスト
    let top_page_id_list: string[] | null = null
    if (top_page) {
      top_page_id_list = query_data.allContentfulBlogPost.edges.map((elem) => {
        return elem.node.id
      })
    }
    // 新着を除外したマスターカテゴリの記事リストを作成
    const master_category_articles = extract_master_category_articles(
      i.node.category,
      pickup_number,
      top_page_id_list
    )
    // 新着を除外した結果、記事数がゼロになった場合は作成しない（push前のbreakで空の配列がreturnされる）
    if (master_category_articles.length === 0) {
      break
    }
    // メタデータ
    const meta_data: input_meta_data_type = {
      master_category_name: i.node.name,
      master_category_slug: i.node.slug,
      published_date:
        i.node.category && i.node.category[0]?.blog_post
          ? i.node.category[0]?.blog_post[0]?.published_date
          : null,
      modified_date:
        i.node.category && i.node.category[0]?.blog_post
          ? i.node.category[0]?.blog_post[0]?.modified_date
          : null,
    }
    result.push(
      enable_metadata && !top_page && (
        <TaxonomyMetadata
          content_type="MasterCategory"
          input_meta_data={meta_data}
          key={`TaxonomyMetadata_${i.node.id}`}
        />
      )
    )
    result.push(
      <div className="taxonomy_title" key={`taxonomy_title_${i.node.id}`}>
        {i.node.name}
      </div>
    )
    result.push(master_category_articles)
    // slugに該当したマスターカテゴリの処理が完了したので、以降の繰り返し処理をカット
    break
  }
  return <>{result}</>
}

export default TaxonomyMasterCategoryList
