/* eslint-disable react/no-danger */
import React from 'react'
import { Link } from 'gatsby'
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image'
import mikan from 'mikanjs'

import { too_long_title } from '../../../style/common_value'
import {
  limit_number_of_characters,
  date_format_dot_separate,
} from '../../../utils'

import {
  card_style,
  post_card_category_style,
  blog_card_info_container_style,
  post_card_info_style,
  post_card_title_style,
  post_card_publish_date_style,
} from './style'

export type blog_post_card_type = {
  id?: string
  title?: string
  slug?: string
  published_date?: string
  thumbnail?: {
    title?: string
    gatsbyImageData?: IGatsbyImageData
  }
  category?: {
    name?: string
    slug?: string
    thumbnail?: {
      title?: string
      gatsbyImageData?: IGatsbyImageData
    }
    master_category?: {
      name?: string
      slug?: string
    }
  }
  custom_class_name?: string
}

type props_type = {
  blog_post_data: blog_post_card_type
}

export const BlogPostCard: React.FC<props_type> = ({ blog_post_data }) => {
  return (
    <div css={card_style} className={blog_post_data.custom_class_name}>
      <Link to={`/${blog_post_data.slug}`}>
        <GatsbyImage
          image={
            blog_post_data.thumbnail?.gatsbyImageData ??
            blog_post_data.category.thumbnail.gatsbyImageData
          }
          alt={
            blog_post_data.thumbnail?.title ??
            blog_post_data.category.thumbnail.title
          }
        />
      </Link>
      <Link to={`/${blog_post_data.slug}`}>
        <div css={post_card_category_style}>{blog_post_data.category.name}</div>
        <div css={blog_card_info_container_style}>
          <div css={post_card_info_style}>
            <div css={post_card_title_style}>
              <div
                dangerouslySetInnerHTML={{
                  __html: mikan(
                    limit_number_of_characters(
                      blog_post_data.title,
                      too_long_title
                    )
                  ),
                }}
              />
            </div>
            <div css={post_card_publish_date_style}>
              {date_format_dot_separate(blog_post_data.published_date)}
            </div>
          </div>
        </div>
      </Link>
    </div>
  )
}

export default BlogPostCard
