import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import { Helmet } from 'react-helmet'

import { JsonLdMetadataQuery } from '../../../../types/graphql-types'

import { metadata_type, metadata_props_type } from './MetadataTypes'

export const JsonLdMetadata: React.FC<metadata_props_type> = ({ metadata }) => {
  const {
    page_type,
    site_title,
    author,
    page_fullpath,
    ogp_images,
    logo_image,
    page_description,
    modified_date,
    published_date,
    page_title,
    posts_list,
    category_name,
  } = metadata

  const { contentfulSiteMetaData: site_metadata }: JsonLdMetadataQuery =
    useStaticQuery(
      graphql`
        query JsonLdMetadata {
          contentfulSiteMetaData {
            site_url
            ogp_download_folder_name
          }
        }
      `
    )

  const add_category_title = category_name
    ? `【${category_name}】${page_title}`
    : `${page_title}`

  const image_directory = `${site_metadata.site_url}/${site_metadata.ogp_download_folder_name}/`
  const image_path = image_directory + ogp_images[0].file.fileName

  const check_type = 'website'

  let jsonld_type: string
  let jsonld_main_entity_of_page_type: string
  if (page_type !== check_type) {
    jsonld_type = 'BlogPosting'
    jsonld_main_entity_of_page_type = 'WebPage'
  } else {
    jsonld_type = 'Blog'
    jsonld_main_entity_of_page_type = 'Blog'
  }

  const add_author = (
    input_post: metadata_type['posts_list'][0],
    input_author: metadata_type['author']
  ): string => {
    const add_authors_author = input_post.author ?? input_author
    return `,
        "author": {
          "@type": "Person",
          "name": "${add_authors_author.author_name}",
          "url": "${add_authors_author.url}"
        }`
  }

  const blog_posts_convert = (
    input_posts: metadata_type['posts_list'],
    input_author: metadata_type['author']
  ): string => {
    const post_json_list = input_posts.map((post) => {
      return `      {
        "@type": "BlogPosting",
        "headline": "${post.headline}",
        "image": "${post.image}"${add_author(post, input_author)}
      }`
    })
    const post_json_combine = post_json_list.join(',\n')
    return `,\n    "blogPost": [\n${post_json_combine}\n    ]`
  }

  const top_page_json = `
  {
    "@context": "https://schema.org",
    "@type": "${jsonld_type}",
    "mainEntityOfPage": {
      "@type": "${jsonld_main_entity_of_page_type}",
      "@id": "${page_fullpath}"
    },
    "headline": "${add_category_title}",
    "image": [
      "${image_path}"
    ],
    "datePublished": "${published_date}",
    "dateModified": "${modified_date ?? published_date}",
    "description": "${page_description}",
    "author": {
      "@type": "Person",
      "name": "${author.author_name}",
      "url": "${author.url}"
    },
    "publisher": {
      "@type": "Organization",
      "name": "${site_title}",
      "logo": {
        "@type": "ImageObject",
        "url": "${logo_image}"
      }
    }${posts_list ? blog_posts_convert(posts_list, author) : ''}
  }
  `

  return (
    <Helmet>
      <script type="application/ld+json">{top_page_json}</script>
    </Helmet>
  )
}
