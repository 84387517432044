import React from 'react'

import { limit_pickup_number, blog_post_sort } from '../../../utils'

import BlogPostCard, { blog_post_card_type } from '../../atoms/BlogPostCard'

type taxonomy_blog_post_core_type = (
  blog_post_list: blog_post_card_type[],
  pickup_number?: number
) => JSX.Element[]

export const blog_post_core: taxonomy_blog_post_core_type = (
  blog_post_list,
  pickup_number
) => {
  const result = []
  if (blog_post_list) {
    // 公開日の日付順にソート
    blog_post_list.sort(blog_post_sort)
    // puckup_numberで記事の数を調整
    let blog_post_list_adjust: blog_post_card_type[]
    if (pickup_number && blog_post_list.length > pickup_number) {
      blog_post_list_adjust = limit_pickup_number(blog_post_list, pickup_number)
    } else {
      blog_post_list_adjust = blog_post_list
    }
    // 記事のリストを作成
    const post_card_list = blog_post_list_adjust.map((elem) => {
      return <BlogPostCard blog_post_data={elem} key={elem.id} />
    })
    // 記事をラップ
    result.push(
      <div className="post_card_list" key="post_card_list">
        {post_card_list}
      </div>
    )
  }
  return result
}

export default blog_post_core
